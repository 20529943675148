import ServiceStationTxlCard from './ServiceStationTxlCard.vue';
import {
    ref,
    reactive,
    toRefs,
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    getCurrentInstance,
    watch,
    provide,
    nextTick
} from 'vue';
import ServiceStationTxlListUtil,{IServiceStationTxlListDataObj} from './serviceStationTxlListUtil';
export default defineComponent ({
    name: 'ServiceStationTxlList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IServiceStationTxlListDataObj=reactive<IServiceStationTxlListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.ToolsProviderApi.buildUrl('/serviceStationTxl/uploadFiles')
                },
                downloadParams:{
                    sourceName:'服务站通讯录模板.xlsx',
                    downLoadFileName:'服务站通讯录模板.xlsx',
                    action:utils.Api.buildUrl('/serviceStationTxl/downLoadResourceFile')
                }
            },
            pageList: {
                multiple:true,
                queryParam: {},
                modelComp: ServiceStationTxlCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/serviceStationTxl/pageData')
            },
            provinceData:[],
            cityData:[],
            otherParams:{
                toolBrandData:[],
                copyBrandId:'',//批量复制到哪个品牌
                dialogVisible:false//批量转移弹出框控制字段
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ServiceStationTxlListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'provinceId'==params.comboId){
                    return dataObj.provinceData
                }
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData
                }
                if(params && 'brandId'==params.comboId){
                    // return [{value:1,label:'金杯'},{value:2,label:'斯威'},{value:3,label:'新能源'}]
                    return dataObj.otherParams.toolBrandData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('provinceId'==selectId){
                if(newValue){
                    await dataObj.utilInst.getCitiesByProvince(newValue);
                }else{
                    await dataObj.utilInst.loadAllCity();
                }
                dataObj.pageList.queryParam.cityId='';
            }
            if('cityId'==selectId){}
        }
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        const afterResult=(res:any,uploadInst:any)=>{
            dataObj.pageListRef.queryHandler(true);
        }
        //批量删除
        const batchDelHandler=()=>{
            dataObj.utilInst.batchOperate({type:'batchDel'});
        }
        //批量复制
        const batchCopyHandler=()=>{
            dataObj.utilInst.batchOperate({type:'batchCopy'});
        }
        //批量转移确认事件
        const sureBatchCopyHandler=async ()=>{
            await dataObj.utilInst.batchCopySure()
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,importHandler,afterResult
            ,batchDelHandler,batchCopyHandler,sureBatchCopyHandler
        }
    }
});