import BaseBean from "@/utils/BaseBean";

export interface IServiceStationTxlListDataObj {
    utilInst:ServiceStationTxlListUtil
    refMap:Map<string,any>
    importGuidParams:any
    pageListRef:any
    pageList: any
    provinceData:Array<any>
    cityData:Array<any>
    otherParams:any
}

export default class ServiceStationTxlListUtil extends BaseBean{
    public dataObj:IServiceStationTxlListDataObj

    constructor(proxy:any,dataObj:IServiceStationTxlListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.toolBrandData=await this.utils.ToolsProviderApi.getAllToolBrand({flag:1});
        this.dataObj.provinceData= await this.utils.Api.loadAllProvince({});
        await this.loadAllCity();
    }
    //加载指定省份下所有的城市
    public async getCitiesByProvince(provinceId:string):Promise<void>{
        this.dataObj.cityData=await this.utils.Api.getCitiesByProvince({provinceId:provinceId});
    }
    //加载所有的城市
    public async loadAllCity():Promise<void>{
        this.dataObj.cityData= await this.utils.Api.loadAllCity({});
    }

    public getSelectRowId():Array<string>{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.utils.Tools.info({message: "请选择要操作的行"});
            return[];
        }
        return selectRows.map((item:any)=>{
            return item.F_ID;
        });
    }
    public async batchOperate(options:any):Promise<void> {
        let selectRows = this.getSelectRowId();
        if(selectRows.length==0)return ;
        Object.assign(options,{selectRows:selectRows});
        if('batchDel'==options.type){
            await this.batchDel(options);
        }else{
            this.dataObj.otherParams.dialogVisible=true;
        }
    }
    //批量删除
    public async batchDel(options:any):Promise<void>{
        this.utils.Tools.configBox({
            message:'确定批量删除所选服务站信息?',
            sureFn:async ()=> {
                let res=await this.utils.ToolsProviderApi.batchServiceStationTxl(options);
                if(res.result)this.dataObj.pageListRef.queryHandler();
            }
        });
    }
    //批量转移
    public async batchCopySure():Promise<void>{
        if(!this.dataObj.otherParams.copyBrandId){
            this.proxy.$message('请选择品牌');
        }else{
            this.dataObj.otherParams.dialogVisible=false;
            let selectRows = this.getSelectRowId();
            let options=Object.assign({type:'batchCopy',selectRows:selectRows,copyBrandId:this.dataObj.otherParams.copyBrandId})
            let res=await this.utils.ToolsProviderApi.batchServiceStationTxl(options);
            if(res.result)this.dataObj.pageListRef.queryHandler();
        }
    }
}